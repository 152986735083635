// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uGDb4qPzoNJ6mtXtEYU7 {\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n}\n\n.uGDb4qPzoNJ6mtXtEYU7.active {\n    background-color: #a4e35f;\n    padding: 0.8rem 1rem;\n    box-shadow: inset 1px 1px 10px -3px #000000;\n}\n\naudio {\n    margin-top: 0.5rem;\n}\n\n.aDkmw6__Usylc661WidT {\n    color: #666;\n    user-select: none;\n}\n\n.uGDb4qPzoNJ6mtXtEYU7.active .aDkmw6__Usylc661WidT {\n    color: #111;\n}\n", "",{"version":3,"sources":["webpack://./src/fe/sound.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,oBAAoB;IACpB,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".sound {\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n}\n\n.sound:global(.active) {\n    background-color: #a4e35f;\n    padding: 0.8rem 1rem;\n    box-shadow: inset 1px 1px 10px -3px #000000;\n}\n\naudio {\n    margin-top: 0.5rem;\n}\n\n.title {\n    color: #666;\n    user-select: none;\n}\n\n.sound:global(.active) .title {\n    color: #111;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sound": "uGDb4qPzoNJ6mtXtEYU7",
	"title": "aDkmw6__Usylc661WidT"
};
export default ___CSS_LOADER_EXPORT___;
