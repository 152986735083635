"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.date = void 0;
const ramda_1 = require("ramda");
const result_1 = require("./result");
const utils_1 = require("./utils");
var date;
(function (date_1) {
    function fromDate(date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            date: date.getDate(),
        };
    }
    date_1.fromDate = fromDate;
    function toDate(date) {
        return new Date(date.year, date.month - 1, date.date);
    }
    date_1.toDate = toDate;
    function fromString(str) {
        try {
            const nums = str.match(/\d+/g);
            (0, utils_1.assert)(nums !== null && nums.length >= 3, 'date format error');
            const [year, month, date] = nums;
            const d = Date.parse(`${year}/${month}/${date}`);
            (0, utils_1.assert)((0, ramda_1.not)(isNaN(d)), 'date format error');
            return result_1.result.ok(fromDate(new Date(d)));
        }
        catch (error) {
            return result_1.result.error(error.message);
        }
    }
    date_1.fromString = fromString;
})(date = exports.date || (exports.date = {}));
