"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.time = exports.assert = exports.AssertError = void 0;
const ramda_1 = require("ramda");
class AssertError extends Error {
}
exports.AssertError = AssertError;
function assert(condition, msg) {
    if ((0, ramda_1.not)(condition)) {
        throw new AssertError(msg);
    }
}
exports.assert = assert;
var time;
(function (time) {
    time.second = 1000;
    time.minute = 60 * time.second;
    time.hour = 60 * time.minute;
    time.day = 24 * time.hour;
})(time = exports.time || (exports.time = {}));
