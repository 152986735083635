"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ramda_1 = require("ramda");
const sound_1 = require("./sound");
const css = require('./sound-group.css').default;
function default_1({ group, active, onActive, onFoldSwitch }) {
    return (React.createElement("div", { className: css.grp },
        (() => {
            if (group.title) {
                return React.createElement(Title, { text: group.title, count: group.items.length, fold: group.fold, onSwitch: onFoldSwitch });
            }
        })(),
        (() => {
            if ((0, ramda_1.not)(group.fold)) {
                return group.items
                    .map(snd => ({
                    resource: snd.resource,
                    playable: snd.playable,
                    name: group.title ? abbr(snd.name, group.title) : snd.name,
                }))
                    .map(snd => React.createElement(sound_1.default, { key: snd.resource, sound: snd, active: snd.resource === active, onActive: () => onActive(snd.resource) }));
            }
        })()));
}
exports.default = default_1;
function Title({ text, count, fold, onSwitch }) {
    return (React.createElement("div", { className: css.title, onClick: onSwitch },
        React.createElement("div", null,
            text,
            "\u00A0",
            fold ? React.createElement("span", { className: 'light' },
                "(\u5171 ",
                count,
                " \u9996)") : ''),
        (() => {
            if (fold) {
                return React.createElement("i", { className: 'bi bi-chevron-down' });
            }
            else {
                return React.createElement("span", { className: 'cmd' }, "\u6536\u5408");
            }
        })()));
}
function abbr(name, title) {
    return name === title ? name : name.slice(title.length);
}
