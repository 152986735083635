"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.name = exports.make = void 0;
const ramda_1 = require("ramda");
function make(items, threshold, getName) {
    return items.reduce((grps, item) => {
        const grp = (0, ramda_1.last)(grps);
        if (grp === undefined) {
            return grps.concat([[item]]);
        }
        else {
            if (sameGrp(getName(item), getName((0, ramda_1.last)(grp)))) {
                return grps.slice(0, -1).concat([grp.concat([item])]);
            }
            else {
                return grps.concat([[item]]);
            }
        }
    }, []);
    function sameGrp(n1, n2) {
        return n1.slice(0, threshold) === n2.slice(0, threshold);
    }
}
exports.make = make;
function name(names) {
    if (names.length === 1) {
        return names[0];
    }
    else {
        const result = names.reduce((gname, name) => getName(gname, name));
        return result === '' ? null : result;
    }
}
exports.name = name;
function getName(a, b) {
    const maxlen = Math.min(a.length, b.length);
    if (maxlen === 0) {
        return '';
    }
    else if (a.slice(0, maxlen) === b.slice(0, maxlen)) {
        return a.slice(0, maxlen);
    }
    else {
        return getName(a.slice(0, maxlen - 1), b.slice(0, maxlen - 1));
    }
}
