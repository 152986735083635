"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.save = exports.load = void 0;
const ts = require("type-schema");
const schema = ts.object({
    user: ts.object({
        email: ts.string(),
        name: ts.string(),
    }),
    data: ts.string(),
    signature: ts.string(),
});
function load() {
    const txt = localStorage.getItem('user');
    if (txt === null) {
        return null;
    }
    else {
        try {
            const [error, user] = ts.transform(schema, JSON.parse(txt));
            return user;
        }
        catch (error) {
            return null;
        }
    }
}
exports.load = load;
function save(user) {
    localStorage.setItem('user', JSON.stringify(user));
}
exports.save = save;
