"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const css = require('./sn-input.css').default;
function default_1({ title, subTitle, onDone }) {
    const [code, setCode] = (0, react_1.useState)('');
    return (React.createElement("div", { id: css.inputer },
        (() => {
            if (title !== undefined) {
                return React.createElement("h3", null, title);
            }
        })(),
        (() => {
            if (subTitle !== undefined) {
                return React.createElement("h4", null, subTitle);
            }
        })(),
        React.createElement("input", { type: 'text', className: 'form-control', placeholder: '\u8ACB\u8F38\u5165\u5E8F\u865F', value: code, onChange: e => setCode(e.target.value) }),
        React.createElement("div", null,
            React.createElement("button", { className: 'btn btn-danger', onClick: () => setCode('') }, "\u91CD\u8A2D"),
            React.createElement("button", { className: 'btn btn-success', onClick: e => onDone(code) }, "\u78BA\u5B9A"))));
}
exports.default = default_1;
