// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZYlkIR54SpEktM_22dqA {\n    margin-top: 1em;\n    margin-bottom: 1em;\n    border-radius: 10px;\n    overflow: hidden;\n}\n.ZYlkIR54SpEktM_22dqA:first-child {\n    margin-top: 0.5em;\n}\n\n.wL9dkS1kw6cXt4_hs5Um {\n    display: flex;\n    font-size: 1.1em;\n    background-color: lavender;\n    padding: 0.5rem;\n}\n\n.wL9dkS1kw6cXt4_hs5Um div:first-child {\n    flex: 1;\n}\n\n.wL9dkS1kw6cXt4_hs5Um .cmd {\n    font-size: 0.9em;\n}\n\n.wL9dkS1kw6cXt4_hs5Um .light {\n    color: #666;\n}\n", "",{"version":3,"sources":["webpack://./src/fe/sound-group.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".grp {\n    margin-top: 1em;\n    margin-bottom: 1em;\n    border-radius: 10px;\n    overflow: hidden;\n}\n.grp:first-child {\n    margin-top: 0.5em;\n}\n\n.title {\n    display: flex;\n    font-size: 1.1em;\n    background-color: lavender;\n    padding: 0.5rem;\n}\n\n.title div:first-child {\n    flex: 1;\n}\n\n.title :global(.cmd) {\n    font-size: 0.9em;\n}\n\n.title :global(.light) {\n    color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grp": "ZYlkIR54SpEktM_22dqA",
	"title": "wL9dkS1kw6cXt4_hs5Um"
};
export default ___CSS_LOADER_EXPORT___;
