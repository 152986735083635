// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#XZ_es9UH7gghRJgM4mAg {\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 1em;\n    border-radius: 10px;\n}\n\n#XZ_es9UH7gghRJgM4mAg h3, #XZ_es9UH7gghRJgM4mAg h4 {\n    text-align: center;\n    font-weight: normal;\n    color: #555;\n}\n\n#XZ_es9UH7gghRJgM4mAg h3 {\n    font-size: 1.2em;\n}\n\n#XZ_es9UH7gghRJgM4mAg h4 {\n    font-size: 1em;\n}\n\n#XZ_es9UH7gghRJgM4mAg div {\n    text-align: center;\n}\n\n#XZ_es9UH7gghRJgM4mAg div button {\n    width: 5em;\n    margin: 8px 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/fe/sn-input.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":["#inputer {\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 1em;\n    border-radius: 10px;\n}\n\n#inputer h3, #inputer h4 {\n    text-align: center;\n    font-weight: normal;\n    color: #555;\n}\n\n#inputer h3 {\n    font-size: 1.2em;\n}\n\n#inputer h4 {\n    font-size: 1em;\n}\n\n#inputer div {\n    text-align: center;\n}\n\n#inputer div button {\n    width: 5em;\n    margin: 8px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputer": "XZ_es9UH7gghRJgM4mAg"
};
export default ___CSS_LOADER_EXPORT___;
