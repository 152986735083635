"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const css = require('./sound.css').default;
function default_1({ sound, active, onActive }) {
    if (active && sound.playable === true) {
        return (React.createElement("div", { className: css.sound + ' active' },
            React.createElement("div", { className: css.title }, sound.name),
            React.createElement("audio", { controls: true },
                React.createElement("source", { src: sound.resource, type: 'audio/mpeg' }),
                "\u60A8\u7684\u700F\u89BD\u5668\u4E0D\u652F\u63F4\u64AD\u653E mp3")));
    }
    else {
        return (React.createElement("div", { className: css.sound, onClick: () => {
                if (sound.playable === true) {
                    onActive();
                }
            } },
            React.createElement("div", { className: css.title },
                sound.playable === false ? '🔒' : '',
                sound.name)));
    }
}
exports.default = default_1;
