"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.load = exports.save = void 0;
const ts = require("type-schema");
const schema = ts.object({
    book: ts.string(),
    sn: ts.string(),
});
function save(book, sn) {
    const results = parse(localStorage.getItem('sn'));
    localStorage.setItem('sn', JSON.stringify(results.concat([{ book, sn }])));
}
exports.save = save;
function load(book) {
    var _a;
    const sns = parse(localStorage.getItem('sn'));
    return ((_a = sns.find(i => i.book === book)) === null || _a === void 0 ? void 0 : _a.sn) || null;
}
exports.load = load;
function parse(data) {
    if (data === null) {
        return [];
    }
    else {
        try {
            const [error, res] = ts.transform(ts.array(schema), JSON.parse(data));
            if (res === null) {
                return [];
            }
            else {
                return res;
            }
        }
        catch (e) {
            return [];
        }
    }
}
