"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.result = void 0;
var result;
(function (result_1) {
    function isError(result) {
        return result[0] !== null;
    }
    result_1.isError = isError;
    function isOk(result) {
        return result[1] !== null;
    }
    result_1.isOk = isOk;
    function all(results) {
        if (results.every(result.isOk)) {
            return [null, results.map(([_, data]) => data)];
        }
        else {
            const errors = results.filter(result.isError).map(([error, _]) => error);
            return [errors[0], null];
        }
    }
    result_1.all = all;
    function ok(data) {
        return [null, data];
    }
    result_1.ok = ok;
    function error(error) {
        return [error, null];
    }
    result_1.error = error;
    function of(result) {
        return new Result(result);
    }
    result_1.of = of;
})(result = exports.result || (exports.result = {}));
class Result {
    constructor(result) {
        this.result = result;
    }
    valueOf() {
        return this.result;
    }
    map(fn) {
        if (result.isOk(this.result)) {
            const [_, data] = this.result;
            return result.of([null, fn(data)]);
        }
        else {
            const [error, _] = this.result;
            return result.of([error, null]);
        }
    }
    chain(fn) {
        if (result.isOk(this.result)) {
            const [_, data] = this.result;
            const res = fn(data);
            if (result.isOk(res)) {
                return result.of(res);
            }
            else {
                const [error, _] = res;
                return result.of([error, null]);
            }
        }
        else {
            const [error, _] = this.result;
            return result.of([error, null]);
        }
    }
}
