"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sn = exports.book = void 0;
var book;
(function (book) {
    function get(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`/api/book/${id}`);
            if (res.status === 204) {
                return null;
            }
            else {
                return res.json();
            }
        });
    }
    book.get = get;
})(book = exports.book || (exports.book = {}));
var sn;
(function (sn_1) {
    function post(user, book, sn) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`/api/sn/${book}/${sn}`, {
                method: 'post',
                body: JSON.stringify({ user }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (res.status === 204) {
                return null;
            }
            else {
                return res.text();
            }
        });
    }
    sn_1.post = post;
    function get(user, book) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`/api/sn/${user}/${book}`);
            return res.json();
        });
    }
    sn_1.get = get;
})(sn = exports.sn || (exports.sn = {}));
