// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules_temp/listener/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#dR0DJjIrfpgdq_KvcIpe {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\nh1 {\n    font-size: 1.5em;\n    font-weight: normal;\n    margin-top: 1em;\n}\n\n#VuWYJFRsfWVW_n48RqKL {\n    min-width: 20em;\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 13px 3em;\n    border-radius: 10px;\n    margin-bottom: 0.5em;\n}\n\n#VuWYJFRsfWVW_n48RqKL .title {\n    text-align: center;\n    font-size: 1.2em;\n    color: #555;\n}\n\n#VuWYJFRsfWVW_n48RqKL .cmd {\n    display: flex;\n    justify-content: space-evenly;\n    margin-top: 0.5em;\n}\n\n#MTPOChQ6XYxJLSMOXI_q {\n    width: fit-content;\n    margin-bottom: 1.5em;\n}\n", "",{"version":3,"sources":["webpack://./src/fe/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,0CAA0C;IAC1C,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":["#book {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\nh1 {\n    font-size: 1.5em;\n    font-weight: normal;\n    margin-top: 1em;\n}\n\n#try {\n    min-width: 20em;\n    background-color: rgba(255, 255, 255, 0.8);\n    padding: 13px 3em;\n    border-radius: 10px;\n    margin-bottom: 0.5em;\n}\n\n#try :global(.title) {\n    text-align: center;\n    font-size: 1.2em;\n    color: #555;\n}\n\n#try :global(.cmd) {\n    display: flex;\n    justify-content: space-evenly;\n    margin-top: 0.5em;\n}\n\n#content {\n    width: fit-content;\n    margin-bottom: 1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"book": "dR0DJjIrfpgdq_KvcIpe",
	"try": "VuWYJFRsfWVW_n48RqKL",
	"content": "MTPOChQ6XYxJLSMOXI_q"
};
export default ___CSS_LOADER_EXPORT___;
