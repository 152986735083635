"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupList = void 0;
var GroupList;
(function (GroupList) {
    function setActive(list, id) {
        return {
            active: id,
            groups: list.groups.map(({ items, title, fold }) => ({
                fold: items.some(i => i.id === id) ? false : fold,
                items, title,
            })),
        };
    }
    GroupList.setActive = setActive;
    function set(state, cmd, groupIndex) {
        return {
            active: state.active,
            groups: state.groups.map((grp, idx) => {
                if (idx === groupIndex) {
                    if (cmd === 'unfold') {
                        return Object.assign(Object.assign({}, grp), { fold: false });
                    }
                    else {
                        if (grp.items.some(i => i.id === state.active)) {
                            return grp;
                        }
                        else {
                            return Object.assign(Object.assign({}, grp), { fold: true });
                        }
                    }
                }
                else {
                    return grp;
                }
            }),
        };
    }
    GroupList.set = set;
    function jumpToNextGroup(state) {
        const [_, curIdx] = state.groups
            .map((grp, idx) => [grp, idx])
            .filter(([grp, idx]) => grp.items.some(i => i.id === state.active))[0];
        const nextGrp = get(state.groups, curIdx + 1);
        const next = get(nextGrp === null || nextGrp === void 0 ? void 0 : nextGrp.items, 0);
        if (next === undefined) {
            return state;
        }
        else {
            return setActive(state, next.id);
        }
        function get(items, index) {
            return items === undefined ? undefined : items[index];
        }
    }
    GroupList.jumpToNextGroup = jumpToNextGroup;
})(GroupList = exports.GroupList || (exports.GroupList = {}));
