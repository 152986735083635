"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sound = void 0;
const ramda_1 = require("ramda");
const result_1 = require("./result");
var sound;
(function (sound) {
    function make(resource) {
        const _name = name(resource);
        if (_name === null) {
            return result_1.result.error('wrong format');
        }
        else {
            return result_1.result.ok({
                id: resource,
                resource,
                name: _name,
            });
        }
    }
    sound.make = make;
    function seq(resource) {
        const fname = (0, ramda_1.last)(resource.split('/'));
        const seq = parseInt(fname.split('-')[0]);
        return isNaN(seq) ? null : seq;
    }
    sound.seq = seq;
    function name(resource) {
        const fname = (0, ramda_1.last)(resource.split('/'));
        const seq = parseInt(fname.split('-')[0]);
        const name = fname.split('-').slice(1).join('-');
        return isNaN(seq) ? null : name;
    }
    sound.name = name;
})(sound = exports.sound || (exports.sound = {}));
