"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const client_1 = require("react-dom/client");
const ramda_1 = require("ramda");
const sound_1 = require("../lib/sound");
const group = require("../lib/group");
const result_1 = require("../lib/result");
const sn_input_1 = require("./sn-input");
const sound_group_1 = require("./sound-group");
const group_list_1 = require("./index/group-list");
const sn = require("./index/sn");
const User = require("./index/user");
const date_1 = require("../lib/date");
const utils_1 = require("../lib/utils");
const api = require("./api");
const css = require('./index.css').default;
function Root() {
    const url = new URL(document.location.href);
    const bookId = url.pathname.replace(/^\//, '');
    const [dom, setDom] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (bookId === '') {
            fetch('/home.dom')
                .then((res) => __awaiter(this, void 0, void 0, function* () { return setDom(yield res.text()); }));
        }
    }, []);
    if (bookId !== '') {
        return React.createElement(App, null);
    }
    else {
        return React.createElement("div", { dangerouslySetInnerHTML: { __html: dom || '' } });
    }
}
function App() {
    const url = new URL(document.location.href);
    const bookId = url.pathname.replace(/^\//, '');
    const [user, setUser] = (0, react_1.useState)(User.load());
    const [book, setBook] = (0, react_1.useState)(null);
    const [state, setState] = (0, react_1.useState)(null); // 曲目
    const [snCode, setSnCode] = (0, react_1.useState)(sn.load(bookId)); // 使用的序號
    const [試聽選項, set試聽選項] = (0, react_1.useState)(null);
    const needSn = book === null ? 'unknown' : book.sn;
    const isDemo = book === null ? 'unknown' : { demo: book.demo };
    const 試聽期內 = (() => {
        if (book === null) {
            return 'unknown';
        }
        else {
            if (book.試聽截止 === null) {
                return false;
            }
            else {
                return (date_1.date.toDate(book.試聽截止).getTime() + utils_1.time.day) > (new Date()).getTime();
            }
        }
    })();
    const playable = (() => {
        if (needSn === 'unknown' || isDemo === 'unknown') {
            return 'unknown';
        }
        else {
            if (needSn) {
                if (試聽期內 === true) {
                    return true;
                }
                else {
                    return snCode !== null;
                }
            }
            else if (isDemo.demo !== null) {
                return false;
            }
            else {
                return true;
            }
        }
    })();
    if (user === null) {
        const cookie = getCookie('user'); // 從登入頁面導回這裡時會產生 cookie
        if (user === null && cookie !== null) {
            User.save(JSON.parse(b64DecodeUnicode(cookie)));
            setUser(JSON.parse(b64DecodeUnicode(cookie)));
        }
    }
    (0, react_1.useEffect)(() => {
        api.book.get(bookId)
            .then((book) => __awaiter(this, void 0, void 0, function* () {
            return book === null ? {
                title: '無此書籍喔',
                sn: false,
                demo: null,
                試聽截止: null,
                resources: [],
            } : book;
        }))
            .then(book => {
            setBook(book);
            const res = makeState(book.resources, playable);
            if (result_1.result.isOk(res)) {
                setState(res[1]);
            }
            else {
                alert(res[0]);
            }
        });
    }, []);
    (0, react_1.useEffect)(() => {
        if (needSn === true) {
            if (user === null) {
                gotoLoginPage(url, bookId);
            }
            else if (snCode === null) {
                api.sn.get(user.user.email, bookId)
                    .then(sns => {
                    if (sns.length > 0) {
                        setSnCode(sns[0]);
                        sn.save(bookId, sns[0]);
                    }
                });
            }
        }
    }, [user === null, needSn, snCode]);
    (0, react_1.useEffect)(() => {
        if (state !== null && playable !== 'unknown') {
            const sounds = state.groups.map(grp => grp.items).flat();
            const res = makeState(sounds.map(i => i.resource), playable);
            if (result_1.result.isOk(res)) {
                const [_, state] = res;
                if (sounds.length > 0) {
                    setState(group_list_1.GroupList.setActive(state, sounds[0].id));
                }
                else {
                    setState(state);
                }
            }
            else {
                alert(res[0]);
            }
        }
    }, [state === null, playable]);
    function postSnCode(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (user !== null) {
                const error = yield api.sn.post(user.user.email, bookId, code);
                if (error === null) {
                    sn.save(bookId, code);
                    setSnCode(code);
                }
                else {
                    if (error === 'not found') {
                        alert('序號錯誤');
                    }
                    else if (error === 'no match') {
                        alert('本序號不屬於這本產品');
                    }
                    else if (error === 'occupied') {
                        alert('本序號已經被註冊過');
                    }
                    else {
                        const _ = error;
                    }
                }
            }
        });
    }
    return (React.createElement("div", { id: css.book },
        React.createElement("h1", null, (book === null || book === void 0 ? void 0 : book.title) || ''),
        (() => {
            // 把國小客語第7冊上面加一個連到第11冊的連結
            if (bookId === 'jtdg-jvig-lpjs') {
                return React.createElement("div", { style: { marginBottom: '10px', fontSize: '1.1em' } },
                    "\u8DF3\u5230",
                    React.createElement("a", { href: 'https://listen.jen-pin.com.tw/pahw-tmia-isow' }, "\u7B2C11\u518A"));
            }
        })(),
        (() => {
            if (needSn === true && 試聽期內 === true && snCode === null) {
                if (試聽選項 === null) {
                    (0, utils_1.assert)(book === null || book === void 0 ? void 0 : book.試聽截止, '');
                    return (React.createElement("div", { id: css.try },
                        React.createElement("div", { className: 'title' },
                            date_1.date.toDate(book.試聽截止).toLocaleDateString(),
                            " \u524D\u514D\u5E8F\u865F\u8A66\u807D\u5594"),
                        React.createElement("div", { className: 'cmd' },
                            React.createElement("button", { className: 'btn btn-success', onClick: () => set試聽選項('直接試聽') }, "\u76F4\u63A5\u8A66\u807D"),
                            React.createElement("button", { className: 'btn btn-success', onClick: () => set試聽選項('輸入序號') }, "\u8F38\u5165\u5E8F\u865F"))));
                }
                else if (試聽選項 === '直接試聽') {
                    return React.createElement(React.Fragment, null);
                }
                else if (試聽選項 === '輸入序號') {
                    return React.createElement(sn_input_1.default, { onDone: postSnCode });
                }
            }
        })(),
        (() => {
            if ((0, ramda_1.not)(playable)) {
                if (typeof isDemo !== 'string' && isDemo.demo !== null) {
                    return React.createElement("div", { dangerouslySetInnerHTML: { __html: isDemo.demo } });
                }
                else {
                    return React.createElement(sn_input_1.default, { title: '\u5148\u8F38\u5165\u5E8F\u865F\u624D\u80FD\u958B\u59CB\u8046\u807D\u5594', subTitle: '\u2665 \u53EF\u5148\u8A66\u807D\u524D\u4E09\u9996 \u2665', onDone: postSnCode });
                }
            }
        })(),
        React.createElement("div", { id: css.content }, (() => {
            if (state !== null) {
                return state.groups.map((grp, idx) => React.createElement(sound_group_1.default, { key: idx, group: grp, active: state.active, onActive: resource => {
                        setState(group_list_1.GroupList.setActive(state, resource));
                    }, onFoldSwitch: () => {
                        const cur = state.groups[idx];
                        if (cur.items.some(i => i.resource === state.active)) {
                            const s = group_list_1.GroupList.jumpToNextGroup(state);
                            setState(group_list_1.GroupList.set(s, 'fold', idx));
                        }
                        else {
                            setState(group_list_1.GroupList.set(state, cur.fold ? 'unfold' : 'fold', idx));
                        }
                    } }));
            }
        })())));
}
function makeState(resources, playable) {
    const _snds = (0, ramda_1.uniq)(resources)
        .sort((a, b) => (sound_1.sound.seq(a) || 0) - (sound_1.sound.seq(b) || 0))
        .map(sound_1.sound.make);
    const snds = result_1.result.all(_snds);
    if (result_1.result.isOk(snds)) {
        const [_, _snds] = snds;
        const sounds = _snds.map((snd, idx) => {
            if (playable === 'unknown') {
                return (0, ramda_1.assoc)('playable', playable, snd);
            }
            else {
                return (0, ramda_1.assoc)('playable', playable || idx < 3, snd);
            }
        });
        const groups = saveLonely(group.make(sounds, 3, sound => sound.name));
        return result_1.result.ok({
            active: null,
            groups: groups.map(items => {
                const title = items.length > 2 ? group.name(items.map(i => i.name)) : null;
                return {
                    fold: title !== null,
                    items, title,
                };
            }),
        });
    }
    else {
        const [error, _] = snds;
        return result_1.result.error(error);
    }
}
/** 合併相鄰且只有一個元件的陣列 */
function saveLonely(groups) {
    if (groups.some(grp => grp.length === 0)) {
        throw new Error('empty group');
    }
    if (groups.length === 0) {
        return [];
    }
    const lonelys = (0, ramda_1.takeWhile)(lonely, groups);
    if (lonelys.length > 1) {
        return [(0, ramda_1.flatten)(lonelys)].concat(saveLonely(groups.slice(lonelys.length)));
    }
    else {
        return [groups[0]].concat(saveLonely(groups.slice(1)));
    }
    function lonely(group) {
        return group.length === 1;
    }
}
function gotoLoginPage(url, bookId) {
    const params = new URLSearchParams();
    params.append('loginUrl', `${url.protocol}//${url.host}/login`);
    params.append('goto', `${url.protocol}//${url.host}/${bookId}`);
    document.location.href = `https://sign-in.jen-pin.com.tw?${params.toString()}`;
}
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}
function b64DecodeUnicode(str) {
    // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
const root = (0, client_1.createRoot)(document.getElementById('root'));
root.render(React.createElement(Root, null));
